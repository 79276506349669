<template>
  <div class="container">
    <div class="top">
      <div class="top_title">
        <div class="title_1"> {{topInfo.title_1}} </div>
        <div class="title_1"> {{topInfo.title_2}} </div>
      </div>
      <div class="top_line"></div>
    </div>
    <div class="mid">
      <div class="mid_item" v-for="item in midList" :key="item">
        <img class="mid_item_img" :src="item.img">
        <div class="mid_item_box">
          <div class="title"> {{item.title}} </div>
          <div class="detail" @click="toDetail(item)">
            <div class="detail_text">查看详情</div>
            <img class="detail_icon" src="https://nontax-cdn.yuluoo.com/hospitalUnionWebsite/union_right_green.png">
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: "case",
    data(){
      return{
        topInfo:{
          title_1: '助力实体医疗机构',
          title_2: '从提供「医疗保障」向提供「健康服务」转型'
        },
        midList:[
          {
            id: 1,
            img: 'https://nontax-cdn.yuluoo.com/hospitalUnionWebsite/union_case_1.png',
            title: '海南省人民医院'
          },
          {
            id: 2,
            img: 'https://nontax-cdn.yuluoo.com/hospitalUnionWebsite/union_case_2.png',
            title: '海口市中医医院'
          },
          {
            id: 3,
            img: 'https://nontax-cdn.yuluoo.com/hospitalUnionWebsite/union_case_3.png',
            title: '海南省第二人民医院'
          },
        ]
      }
    },
    methods:{
      toDetail(item){
        if(item.id == 1){
          this.$router.push({ path: '/case_detail' })
        }else if(item.id == 2){
          this.$router.push({ path: '/case_detail_3' })
        }else if(item.id == 3){
          this.$router.push({ path: '/case_detail_2' })
        }
      }
    },
    mounted(){
      window.scrollTo({ top: 0, behavior: "smooth" });
    }
  };
</script>

<style lang="stylus" scoped>
  .container{
    display flex
    flex-flow column
    font-family Alibaba PuHuiTi 2.0
    font-weight normal
    background #F5F5F7
    .top{
      height 700px
      background url("https://nontax-cdn.yuluoo.com/hospitalUnionWebsite/union_case_bg.png") no-repeat
      background-size 100% 100%
      box-sizing border-box
      padding 0 140px
      .top_title{
        font-size 56px
        color #343A40
        margin-top 207px
      }
      .top_line{
        width 250px
        height 20px
        background #007BFF
        margin-top 30px
      }
    }
    .mid{
      display flex
      justify-content space-between
      box-sizing border-box
      padding 0 140px
      margin-top -80px
      .mid_item{
        display flex
        flex-flow column
        background #fff
        border-radius 24px
        overflow hidden
        .mid_item_img{
          width 520px
          height 401px
          transition all 2s
          overflow hidden
        }
        .mid_item_img:hover{
          transform scale(1.5)
          overflow hidden
        }
        .mid_item_box{
          display flex
          flex-flow column
          box-sizing border-box
          padding 30px
          z-index 666
          background #fff
          .title{
            font-size 32px
            line-height 56px
            color #343A40
          }
          .detail{
            display flex
            align-items center
            margin-top 20px
            cursor pointer
            .detail_text{
              font-size 24px
              line-height 33px
              color #17A2B8
            }
            .detail_icon{
              width 21px
              height 21px
              margin-left 8px
            }
          }
        }
      }
    }
  }
</style>
